<!-- 智慧教学/综评档案/综评成绩 -->
<template>
    <div>
		<s-edu-archives-score-wrap></s-edu-archives-score-wrap>
    </div>
</template>
<script>
import SEduArchivesScoreWrap from "../components/scrollWrapper/SEduArchives/Score.vue";

export default {
    name: "SEduArchivesScore",
    components: {
        SEduArchivesScoreWrap
    }
}
</script>
