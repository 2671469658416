<!-- 智慧教学/课堂管理/课堂量化 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SEduClassroomNumber",
        components: {
            Building
        }
    }
</script>