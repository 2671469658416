<!-- 教师成长/课程中心 -->
<template>
	<Building />
</template>
<script>
	import Building from './Building.vue';
	export default {
		name: "SEduSeduCourseCenter",
		components: {
			Building
		}
	}

</script>