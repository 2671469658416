<template>
  <div>
    <!-- 巡课记录 -->
    <teaching-research-classroom-patrol-records></teaching-research-classroom-patrol-records>
  </div>
</template>

<script>
import TeachingResearchClassroomPatrolRecords from '../components/scrollWrapper/TeachingResearchClassroomPatrolRecords.vue'
export default {
    name:"SEduClassroomPatrolRecord",
    components:{
        TeachingResearchClassroomPatrolRecords
    }
}
</script>

<style>

</style>
