<!-- 智慧教学/课堂管理/课堂行为管理/行为标签 -->
<template>
    <Building />
</template>
<script>
import Building from './Building.vue';
export default {
    name: "SEduClassroomBehaviorMark",
    components: {
        Building
    }
}
</script>