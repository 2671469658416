<!-- 智慧教学/综评档案/等级设置 -->
<template>
	<div>
		<s-edu-archives-level-wrap></s-edu-archives-level-wrap>
	</div>
</template>
<script>
	import SEduArchivesLevelWrap from "../components/scrollWrapper/SEduArchives/Level.vue";

	export default {
		name: "SEduArchivesLevel",
		components: {
			SEduArchivesLevelWrap
		}
	}
</script>