<!--  智慧教学/课堂管理/网课管理/湖城云课 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SEduClassroomWebLearnHu",
        components: {
            Building
        }
    }
</script>