<!-- 智慧教学/课堂管理/巡课管理/巡课方案 -->
<template>
  <div>
    <SEduClassroomPatrolProgrammeIndex />
  </div>

</template>
<script>
import SEduClassroomPatrolProgrammeIndex from '@/components/scrollWrapper/SEduClassroomPatrolProgrammeIndex.vue';
export default {
  name: "SEduClassroomPatrolProgramme",
  components: {
    SEduClassroomPatrolProgrammeIndex
  }
}
</script>