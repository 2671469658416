<!-- 智慧教学/课堂管理/巡课管理/巡课内容 -->
<template>
  <div>
    <SEduClassroomPatrolContentIndex />
  </div>

</template>
<script>
import SEduClassroomPatrolContentIndex from '@/components/scrollWrapper/SEduClassroomPatrolContentIndex.vue';
export default {
  name: "SEduClassroomPatrolContent ",
  components: {
    SEduClassroomPatrolContentIndex
  }
}
</script>