<!-- 智慧教学/智慧教研/教学资源 -->
<template>
    <div>
        <s-edu-sedu-edu-source-box></s-edu-sedu-edu-source-box>
    </div>
</template>
<script>
import SEduSeduEduSourceBox from "../components/scrollWrapper/SEduSeduEduSourceBox.vue";

export default {
    name: "SEduSeduEduSource",
    components: {
        SEduSeduEduSourceBox
    }
}
</script>
