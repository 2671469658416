<!-- 智慧教学/综评档案/方案配置 -->
<template>
	<div>
		<s-edu-archives-programme-wrap></s-edu-archives-programme-wrap>
	</div>
</template>
<script>
	import SEduArchivesProgrammeWrap from "../components/scrollWrapper/SEduArchives/Programme.vue";

	export default {
		name: "SEduArchivesProgramme",
		components: {
			SEduArchivesProgrammeWrap
		}
	}
</script>