<!-- 智慧教学/课程管理/作息时间表 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SEduLessonWorkTime",
        components: {
            Building
        }
    }
</script>