<!-- 智慧教学/综评档案 -->
<template>
	<div>
		<KeepAliveRouterView />
	</div>
</template>
<script>
	export default {
		name: "SEduArchives"
	}
</script>