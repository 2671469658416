<!-- 智慧教学/课程管理/课程安排 -->
<template>
    <Building />
</template>
<script>
import Building from './Building.vue';
export default {
    name: "SEduLessonSubjectPlan",
    components: {
        Building
    }
}
</script>