<!-- 智慧教学/课堂管理/课堂行为管理/课堂观察记录 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SEduClassroomBehaviorRecord",
        components: {
            Building
        }
    }
</script>