<!-- 教师成长/课程管理 -->
<template>
	<Building />
</template>
<script>
	import Building from './Building.vue';
	export default {
		name: "SEduSeduCourseManagement",
		components: {
			Building
		}
	}
</script>