<!-- 智慧教学/课堂管理/智慧教室 -->
<template>
  <div>
    <Building />
    <!-- 123123 -->
  </div>

</template>
<script>
import Building from './Building.vue';
export default {
  name: "SEduClassroomSClass",
  components: {
    Building
  }
}
</script>